import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React from 'react'

type TextInputProps = {
  value?: string | number
  required?: boolean
  name: string
  type?: string
  label?: string
  disabled?: boolean
  autoComplete?: string
  className?: string
  wrapperClassName?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string | false
  placeholder?: string
  min?: number
  max?: number
}

const TextInput = ({
  value,
  required,
  name,
  type = 'text',
  label,
  min,
  max,
  disabled,
  autoComplete,
  className,
  wrapperClassName,
  onChange,
  onBlur,
  error,
  placeholder,
}: TextInputProps) => {
  return (
    <div className="form-control w-full">
      {label && (
        <div className="flex items-center">
          <label htmlFor={name} className="label">
            <span className={classNames('label-text', { 'text-red-600': error })}>
              {label}
            </span>
          </label>
          {required && <span className="text-red-600"> *</span>}
        </div>
      )}
      <div className={classNames('relative rounded-md shadow-sm', wrapperClassName)}>
        <input
          disabled={disabled}
          name={name}
          id={name}
          type={type}
          value={value}
          min={min}
          max={max}
          autoComplete={autoComplete}
          placeholder={placeholder}
          className={classNames('input input-bordered w-full', className)}
          onChange={onChange}
          onBlur={onBlur}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>

      {error === false ? null : (
        <p className="mt-2 text-sm text-red-600 min-h-6" id="email-error">
          {error}
        </p>
      )}
    </div>
  )
}

export default TextInput
